import styled, { keyframes } from 'styled-components';

const fadeInAndOut = keyframes`
    0% {
      opacity: 0.3;
    }
    25%{
        opacity: 0.8;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.3;
    }
`;

export const StyledLoaderContainer = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  position: fixed;
  z-index: 99999999;
  background-color: ${({ theme }) => theme.palette.white};
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .animating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    animation-name: ${fadeInAndOut};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
`;
