import React from 'react';
import { StyledLoaderContainer } from './Loader.styled';
import Image from 'next/image';

const Loader = () => {
  return (
    <StyledLoaderContainer>
      <div className="animating">
        <Image src="/8favicon-195.svg" alt="WeAre8 Logo" width={160} height={160} priority />
        <Image src="/word-mark.svg" alt="WeAre8" width={160} height={35} priority />
      </div>
    </StyledLoaderContainer>
  );
};

export default Loader;
